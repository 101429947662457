import { Button, Modal } from "react-bootstrap";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  addCreate,
  addUpdate,
  addusers,
  findAllRoles,
  findallspokes,
  getallAdditionalbranch,
  getallbranch,
  getallregion,
  getallstates,
  getallzones,
  getuserbyid,
} from "../../../Utils/Services/employeeMaster";
import {
  checkIfNotDecimal,
  formatIndianCurrency,
  replaceComma,
} from "../../../Utils/helperFunctions";
import { cloneDeep, result } from "lodash";

import CloseModal from "../../../Components/CloseModal";
import Select from "react-select";
import { configureStore } from "../../../store";

toast.configure();

const initForm = {
  vertical: null,
  employeeName: "",
  employeeFirstName: "",
  employeeLastName: "",
  employeeId: "",
  emailId: "",
  mobileNo: "",
  role: "",
  status: "ACTIVE",
  zoneDTO: {
    id: "",
    zoneName: "",
  },
  stateDTO: {
    id: "",
    stateName: "",
  },
  regionDTO: {
    id: "",
    regionName: "",
  },
  branchDTO: {
    id: "",
    branchName: "",
  },
  nonPrimaryBranchSet: null,
  spokeDTOSet: [{}],

  errors: {
    employeeName: null,
    employeeFirstName: null,
    employeeLastName: null,
    employeeId: null,
    emailId: null,
    mobileNo: null,
    role: null,
    zoneDTO: null,
    stateDTO: null,
    regionDTO: null,
    branchDTO: null,
    spokeDTO: null,
    vertical: null,
  },
  status: "ACTIVE",
};

const statusType = [
  { statusLabel: "ACTIVE", statusValue: "ACTIVE" },
  { statusLabel: "INACTIVE", statusValue: "INACTIVE" },
];

const verticalDropdown = [
  { verticalLabel: "SarvaGram Solutions", verticalValue: "solutions" },
  { verticalLabel: "SarvaGram Fincare", verticalValue: "fincare" },
];

const dropstyle = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};

export class Addedituser extends Component {
  state = {
    showModal: false,
    isLoading: false,
    showInner: false,
    form: cloneDeep(initForm),
    key: Math.random(),
    userBranch: [],
    userZone: [],
    userState: [],
    userRegion: [],
    userVillage: [],
    zoneDropdownValue: [],
    stateDropdownValue: [],
    branchDropdownValue: [],
    branchAdditinalDropdownValue: [],
    regionDropdownValue: [],
    villageDropdownValue: [],
    spokesList: [],
    loaderForSpoke: false,
    showZone: false,
    showState: false,
    showRegion: false,
    showBranch: false,
    showSpoke: false,
    showUpdate: true,
    showGeography: false,
  };

  componentDidMount = () => {
    let {
      form,
      obj1,
      zoneDropdownValue,
      stateDropdownValue,
      regionDropdownValue,
      branchDropdownValue,
      branchAdditinalDropdownValue,
      villageDropdownValue,
      spokesList,
    } = this.state;
    //form.spokeDTOSet=[];
    let { editData } = this.props;
    this.setState({ showModal: true });
    // console.log("props.editData", this.props.editData);
    if (this.props.editData && this.props.action == "edit") {
      // this.getzones();

      let { form, showZone, showRegion, showBranch, showSpoke, showState } =
        this.state;

      let obj = {
        ...form,
        ...this.props.editData,
      };

      let { roleDropdownValue } = this.props;

      var geography = null;
      roleDropdownValue.forEach((roleDropdown) => {
        if (roleDropdown.roleName === obj.role) {
          geography = roleDropdown.geography;
        }
      });

      this.getuser(this.props.editData.id);

      let indexNew =
        editData &&
        editData.village &&
        editData.village.findIndex((e) => e.villageName === "All");
      let userVillage1 = [];
      if (indexNew > -1) {
        userVillage1.push(
          editData &&
            editData.village &&
            editData.village.filter((e) => e.villageName === "All")
        );
      }
      let newdata =
        userVillage1 && userVillage1.length > 0
          ? userVillage1[0]
          : editData.village;

      newdata &&
        newdata.map((e) => {
          e.id = e.pincodeVillageMappingId;
        });

      this.setShowGeography(geography);

      this.setState({
        form: obj,
        userVillage: newdata,
        key: Math.random(),
      });
    }
    // this.getzones();
    if (this.props && this.props.action == "add") this.getzones();
  };

  getzones = () => {
    getallzones().then((result) => {
      if (result.error) {
        // return;
      } else if (result.data && result.data.error) {
        //toast.error(result.data.message, {
        //type: toast.TYPE.ERROR,
        // autoClose: 2000,
        //  });
      } else {
        console.log("helllp1");
        if (result && result.data) {
          console.log("helllp");
          this.setState({
            zoneDropdownValue: result && result.data,
          });
        }
      }
    });
  };

  getstates = (id) => {
    let { form } = this.state;
    // let obj = {
    //   zoneName: form.zone,
    // };
    // let obj = {
    //   zoneName: form.zone,
    //   id: form.id,
    // }
    if (id === null) {
      return;
    } else
      getallstates(id).then((result) => {
        if (result.error) {
          console.log("stateDropdownValue", id);
          // return;
        } else if (result && result.error) {
          console.log("stateDropdownValue2", id);
          toast.error("states not found", {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        } else {
          if (result && result.data) {
            console.log("stateDropdownValue3", result.data);
            this.setState({
              stateDropdownValue: result && result.data,
            });
          }
        }
      });
  };

  getregions = (id) => {
    let { form } = this.state;
    // let obj = {
    //   zoneName: form.zone,
    //   stateName:form.state,
    // };
    if (id === null) {
      return;
    } else
      getallregion(id).then((result) => {
        if (result.error) {
          // return;
        } else if (result.data && result.data.error) {
          // toast.error(result.data.message, {
          //   type: toast.TYPE.ERROR,
          //   autoClose: 2000,
          // });
        } else {
          if (result && result.data) {
            this.setState({
              regionDropdownValue: result && result.data,
            });
          }
        }
      });
  };

  getAdditionalbranches = (id) => {
    let { form } = this.state;
    if (id === null) {
      return;
    } else
      getallAdditionalbranch(id).then((result) => {
        if (result && result.error) {
          toast.error(result.data.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
        } else {
          if (result && result.data) {
            this.setState({
              branchAdditinalDropdownValue: result && result.data,
            });
          }
        }
      });
  };
  getbranches = (id) => {
    console.log("id", id);
    let { form } = this.state;
    // let obj = {
    //   zoneName: form.zone,
    //   stateName:form.state,
    //   regionName: form.region,
    // };
    if (id === null) {
      return;
    } else
      getallbranch(id).then((result) => {
        // console.log("reee", result);
        if (result.error) {
          // return;
        } else if (result && result.error) {
          // toast.error(result.data.message, {
          //   type: toast.TYPE.ERROR,
          //   autoClose: 2000,
          // });
        } else {
          if (result && result.data) {
            console.log("branchdropdown", id);
            this.setState({
              branchDropdownValue: result && result.data,
            });
          }
        }
      });
  };

  getspokes = (id) => {
    let { form } = this.state;
    console.log("branchid", id);
    // let obj = {
    //   zoneName: form.zone,
    //   stateName:form.state,
    //   regionName: form.region,
    // };
    if (id === null) {
      return;
    } else
      findallspokes(id).then((result) => {
        // console.log("reee", result);
        if (result.error) {
          // return;
        } else if (result && result.error) {
          // toast.error(result.data.message, {
          //   type: toast.TYPE.ERROR,
          //   autoClose: 2000,
          // });
        } else {
          if (result && result.data) {
            let { form, villageDropdownValue } = this.state;
            console.log("villagedropdown1", result.data, form.spokeDTOSet);
            villageDropdownValue = [];
            console.log("listtt", villageDropdownValue);
            this.setState({
              villageDropdownValue: result && result.data,
            });
          }
        }
      });
  };

  getuser = (obj) => {
    console.log("obj", obj);
    let { form } = this.state;
    getuserbyid(obj).then((res) => {
      console.log("*****", res);
      if (res.error) {
        return;
      }
      if (res && res.error) {
        toast.error(res.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        });
      } else {
        /*  toast.success(res.data.message, {
                type: toast.TYPE.SUCCESS,
                autoClose: 2000
            }); */
        //   setEditData(res.data);
        // setuserContainer(res.data);
        let { form, obj1 } = this.state;
        form.vertical = res && res.data && res.data.vertical;
        form.employeeFirstName = res && res.data && res.data.employeeFirstName;
        form.employeeLastName = res && res.data && res.data.employeeLastName;
        console.log("vertical", form.vertical);
        if (res && res.data && res.data.spokeDTOSet != null)
          form.spokeDTOSet = res && res.data && res.data.spokeDTOSet;
        console.log("onedit", form.spokeDTOSet);
        if (res && res.data && res.data.nonPrimaryBranchSet != null)
          form.nonPrimaryBranchSet =
            res && res.data && res.data.nonPrimaryBranchSet;
        if (res && res.data && res.data.branchDTO != null)
          form.branchDTO = res && res.data && res.data.branchDTO;
        if (res && res.data && res.data.branchDTO == null)
          form.branchDTO = { id: "", branchName: "" };

        if (res && res.data && res.data.regionDTO != null)
          form.regionDTO = res && res.data && res.data.regionDTO;
        if (res && res.data && res.data.regionDTO == null)
          form.regionDTO = { id: "", regionName: "" };

        if (res && res.data && res.data.stateDTO != null)
          form.stateDTO = res && res.data && res.data.stateDTO;

        if (res && res.data && res.data.zoneDTO != null)
          form.zoneDTO = res && res.data && res.data.zoneDTO;

        console.log("branchDTO", form);
        //this.getstates(form.branchDTO.id);
        this.getzones();
        if (form && form.spokeDTOSet !== null) {
          this.getspokes(form && form.branchDTO && form.branchDTO.id);
          console.log("branch");
        }
        if (form && form.branchDTO && form.branchDTO.id !== "") {
          console.log("region");
          this.getbranches(form && form.regionDTO && form.regionDTO.id);
        }
        if (form && form.regionDTO && form.regionDTO.id !== "") {
          console.log("state");
          this.getregions(form && form.stateDTO && form.stateDTO.id);
          this.getAdditionalbranches(
            form && form.stateDTO && form.stateDTO.stateName
          );
        }
        if (form && form.stateDTO && form.stateDTO.id !== "") {
          console.log("zone");
          this.getstates(form && form.zoneDTO && form.zoneDTO.id);
        }

        this.setState({ form, obj1 });
      }
    });
  };

  getvillage = () => {
    let { form, loaderForSpoke } = this.state;
    let obj = {
      zoneName: form.zoneDTO,
      stateName: form.stateDTO,
      regionName: form.regionDTO,
      branchName: form.branchDTO,
    };
    this.setState({ loaderForSpoke: true });
    findallspokes(obj).then((result) => {
      // findallspokes(obj).then((result) => {
      if (result.error) {
        this.setState({ loaderForSpoke: false });
        return;
      } else if (result.data && result.data.error) {
        // toast.error(result.data.message, {
        //   type: toast.TYPE.ERROR,
        //   autoClose: 2000,
        // });
        this.setState({ loaderForSpoke: false });
      } else {
        if (result && result.data && result.data.data) {
          this.setState({ loaderForSpoke: false });
          let villagedropdown = result.data.data;
          villagedropdown.forEach((res) => {
            if (res.villageName === "All") {
              // condition is used for only show the village name for ALL
              res.info = `${res.villageName}`;
            } else {
              res.info = `${res.villageName}` + `(${res.pincode})`;
            }
          });
          this.setState({
            villageDropdownValue: villagedropdown,
          });
        }
      }
    });
  };

  handleModalClose = () => {
    this.setState({ showInner: !this.state.showInner });
    // this.this.props.addRole();
  };

  innerModalClose = (modclose) => {
    if (!modclose) {
      this.handleModalClose();
    }
    this.setState({ showModal: false });
    if (this.props.action == "edit") {
      this.props.handleEditUser();
    } else {
      this.props.handleAddUser();
    }
  };

  handleSubmit = () => {
    let {
      form,
      userBranch,
      userZone,
      userState,
      userRegion,
      userVillage,
      villageDropdownValue,
    } = this.state;
    let { editData } = this.props;
    let employeeCreationFrom = {
      id: form.id,
      vertical: form.vertical,
      employeeName: form.employeeName,
      employeeFirstName: form.employeeFirstName,
      employeeLastName: form.employeeLastName,
      employeeId: form.employeeId,
      emailId: form.emailId,
      mobileNo: form.mobileNo,
      role: form.role,
      status: form.status,
      zoneDTO: form.zoneDTO,
      stateDTO: form.stateDTO,
      regionDTO: form.regionDTO,
      branchDTO: {
        id: form.branchDTO.id,
        branchName: form.branchDTO.branchName,
      },
      nonPrimaryBranchSet: form.nonPrimaryBranchSet,
      spokeDTOSet: form.spokeDTOSet,
    };

    employeeCreationFrom.role =
      employeeCreationFrom.role === "BCM1"
        ? "BCM 1"
        : employeeCreationFrom.role;
    let obj = cloneDeep(employeeCreationFrom);

    delete obj.errors;
    delete obj.zone;
    delete obj.region;

    console.log({ obj });

    this.setState({ isLoading: true });
    if (this.props.action == "edit") {
      addUpdate(obj).then((response) => {
        this.setState({ isLoading: false });
        if (response && response.error === true) {
          toast.error(response.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          this.props.allusers();
          if (this.props.editData && this.props.action == "edit") {
            this.props.handleEditUser();
            toast.success("Data Updated successfully", {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          } else {
            this.props.handleAddUser();
            toast.success("Data Added successfully", {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        }
      });
    } else {
      addCreate(obj).then((response) => {
        this.setState({ isLoading: false });
        console.log("responseforsave", response);

        if (response && response.error === true) {
          toast.error(response.message, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
          });
          return;
        } else {
          this.props.allusers();
          if (this.props.editData && this.props.action == "edit") {
            this.props.handleEditUser();
            toast.success("Data Updated successfully", {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          } else {
            this.props.handleAddUser();
            toast.success("Data Added successfully", {
              type: toast.TYPE.SUCCESS,
              autoClose: 2000,
            });
          }
        }
      });
    }
  };

  handleInputChange = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    console.log("handleInputChange", e, name, e.target.value);
    form[name] = e.target.value;
    this.setState({ form });
    // if(form.name !== e.target.value){
    this.setState({ showUpdate: false });
    // }
  };

  handleroleSelectChange = (e, name) => {
    console.log("roleName11", e);
    this.clearZone();
    this.clearState();
    this.clearRegion();
    this.clearBranch();
    this.clearSpoke();
    this.getzones();

    let { form } = this.state;
    let { roleName, geography } = e;
    form[name] = e.roleCode;

    form.zone = "";

    this.setShowGeography(geography);

    this.setState({ form });

    this.setState({ showUpdate: true });
  };

  setShowGeography = (geography) => {
    let {
      showZone,
      showRegion,
      showBranch,
      showSpoke,
      showGeography,
      showState,
    } = this.state;

    if (geography === null || geography === undefined || geography === "") {
      showGeography = true;
    } else {
      showGeography = false;
    }

    if (geography !== "Nation") {
      showZone = true;
    } else {
      showZone = false;
    }

    if (geography !== "Nation" && geography !== "Zone") {
      showState = true;
    } else {
      showState = false;
    }

    if (
      geography !== "Nation" &&
      geography !== "Zone" &&
      geography !== "State"
    ) {
      showRegion = true;
    } else {
      showRegion = false;
    }

    if (
      geography !== "Nation" &&
      geography !== "Zone" &&
      geography !== "State" &&
      geography !== "Region"
    ) {
      showBranch = true;
    } else {
      showBranch = false;
    }

    if (
      geography !== "Nation" &&
      geography !== "Zone" &&
      geography !== "State" &&
      geography !== "Region" &&
      geography !== "Branch"
    ) {
      showSpoke = true;
      console.log("showSpoke", showSpoke);
    } else {
      showSpoke = false;
    }

    if (geography == "" || geography == null || geography == undefined) {
      return;
    }

    this.setState({
      showGeography,
      showZone,
      showState,
      showRegion,
      showBranch,
      showSpoke,
    });
  };

  handleZoneSelectChange = (e, name) => {
    console.log("zoneName", e, name);
    this.clearState();
    this.clearRegion();
    this.clearBranch();
    this.clearSpoke();

    let { form, zoneDropdownValue, showZone } = this.state;
    let { zoneName } = e;
    for (var i = 0; i < zoneDropdownValue.length; i++) {
      if (zoneDropdownValue[i].zoneName == zoneName) {
        console.log("ZoneDropDown", zoneDropdownValue[i]);
        form.zoneDTO.id = zoneDropdownValue[i].id;
      }
    }
    console.log("id", form);

    form.zoneDTO.zoneName = zoneName;
    if (
      ((form && form.zoneDTO.zoneName === "") ||
        form.zoneDTO.zoneName === null ||
        form.zoneDTO.zoneName === undefined) &&
      showZone
    ) {
      if (form && form.errors) {
        console.log("zoneerror");
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        console.log("zonescheck");
        form.errors[name] = false;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });

    //this.getregions();
    this.getstates(form.zoneDTO.id);
  };
  handleStateSelectChange = (e, name) => {
    this.clearRegion();
    this.clearBranch();
    console.log("stateName", e, name);
    let { form, stateDropdownValue, showstate } = this.state;
    let { stateName } = e;
    for (var i = 0; i < stateDropdownValue.length; i++) {
      if (stateDropdownValue[i].stateName == stateName) {
        console.log("hello1", stateDropdownValue[i]);
        form.stateDTO.id = stateDropdownValue[i].id;
      }
    }
    form.stateDTO.stateName = stateName;
    if (
      form &&
      (form.stateDTO.stateName === "" ||
        form.stateDTO.stateName === null ||
        form.stateDTO.stateName === undefined) &&
      showstate
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = false;
        this.setState({ form });
      }
    }
    this.setState({ form, key: Math.random() });
    this.clearAllRegion();
    this.getregions(form.stateDTO.id);
    this.getAdditionalbranches(form.stateDTO.stateName);
  };

  handleRegionSelectChange = (e, name) => {
    this.clearBranch();
    this.clearAllRegion();
    this.clearSpoke();
    console.log("regionName", e, name);
    let {
      form,
      regionDropdownValue,
      showZone,
      showRegion,
      showBranch,
      showSpoke,
    } = this.state;
    let { regionName } = e;
    for (var i = 0; i < regionDropdownValue.length; i++) {
      if (regionDropdownValue[i].regionName == regionName) {
        console.log("hello2", regionDropdownValue[i]);
        form.regionDTO.id = regionDropdownValue[i].id;
      }
    }
    form.regionDTO.regionName = regionName;
    if (
      form &&
      (form.regionDTO.regionName === "" ||
        form.regionDTO.regionName === null ||
        form.regionDTO.regionName === undefined) &&
      showRegion
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = false;
        this.setState({ form });
      }
    }
    // console.log("checks",showZone,showRegion,showBranch,showSpoke);
    this.setState({ form, key: Math.random() });
    this.clearAllBranch();
    console.log("rform", form);
    this.getbranches(form.regionDTO.id);
  };

  handleBranchSelectChange = (e, name) => {
    console.log("branchName", e, name);
    this.clearSpoke();
    let {
      form,
      branchDropdownValue,
      showZone,
      showRegion,
      showBranch,
      showSpoke,
    } = this.state;
    let { branchName } = e;
    for (var i = 0; i < branchDropdownValue.length; i++) {
      if (branchDropdownValue[i].branchName == branchName) {
        console.log("hello23", branchDropdownValue[i]);

        branchDropdownValue[i].branchMappingEntitySet = "";
        form.branchDTO = branchDropdownValue[i];
      }
    }
    form.branchDTO.branchName = branchName;
    if (
      form &&
      (form.branchDTO.branchName === "" ||
        form.branchDTO.branchName === null ||
        form.branchDTO.branchName === undefined) &&
      showBranch
    ) {
      if (form && form.errors) {
        form.errors[name] = true;
        this.setState({ form });
      }
    } else {
      if (form && form.errors) {
        form.errors[name] = false;
        this.setState({ form });
      }
    }
    console.log("checks1", showZone, showRegion, showBranch, showSpoke);

    this.setState({ form });
    //this.getvillage();
    this.getspokes(form.branchDTO.id);
  };

  // handlebranch = (branch) => {
  //   // let { errors } = this.state;

  //   // if (
  //   //   branch === "" ||
  //   //   branch === null ||
  //   //   branch === undefined ||
  //   //   branch.length === 0
  //   // ) {
  //   //   this.setState({ errors: { ...errors, branch: true } });
  //   // } else {
  //   //   this.setState({ errors: { ...errors, branch: false } });
  //   // }

  //   let brancharray = [];
  //   branch && brancharray.push(branch);

  //   this.setState({ userBranch: brancharray[0] });
  // };

  // handleZone = (zone) => {
  //   // let { errors } = this.state;
  //   // if (
  //   //   zone === "" ||
  //   //   zone === null ||
  //   //   zone === undefined ||
  //   //   zone.length === 0
  //   // ) {
  //   //   this.setState({ errors: { ...errors, zone: true } });
  //   // } else {
  //   //   this.setState({ errors: { ...errors, zone: false } });
  //   // }

  //   let zonearray = [];
  //   zone && zonearray.push(zone);

  //   this.setState({ userZone: zonearray[0] });
  // };

  // handleRegion = (region) => {
  //   // let { errors } = this.state;
  //   // if (
  //   //   region === "" ||
  //   //   region === null ||
  //   //   region === undefined ||
  //   //   region.length === 0
  //   // ) {
  //   //   this.setState({ errors: { ...errors, region: true } });
  //   // } else {
  //   //   this.setState({ errors: { ...errors, region: false } });
  //   // }

  //   let regionarray = [];
  //   region && regionarray.push(region);

  //   this.setState({ userRegion: regionarray[0] });
  // };

  handleVillage = (village) => {
    let { userVillage, villageDropdownValue } = this.state;
    console.log("spokedto", village);
    // let villagearray = [];
    // village && villagearray.push(village);

    // newindex variable is used to get the ALL value

    // let newindex =
    //   villagearray &&
    //   villagearray[0] &&
    //   villagearray[0].findIndex((e) => e.villageName === "All");
    // if (newindex > -1 && newindex === village.length - 1) {
    //   villagearray = [];
    //   villagearray.push(
    //     villageDropdownValue &&
    //       villageDropdownValue.filter((e) => e.villageName === "All")
    //   );

    // } else if (newindex == 0) {
    //   villagearray[0].splice(0, 1);
    // }

    let { form, showSpoke } = this.state;

    if (
      (village === "" || village === null || village === undefined) &&
      showSpoke
    ) {
      form.errors.spokeDTO = true;
    } else {
      form.errors.spokeDTO = false;
    }
    this.setState({ form });

    form.spokeDTOSet = village;
    this.setState({ form });

    //this.setState({ userVillage: villagearray[0] });
  };

  handleAdditionalBranches = (village) => {
    let { userVillage, villageDropdownValue } = this.state;
    let { form, showSpoke } = this.state;
    this.setState({ form });
    form.nonPrimaryBranchSet = village;
    this.setState({ form });
  };

  handleVerticalChange = (e, name) => {
    let { form } = this.state;
    form.vertical = e?.verticalValue;
    this.setState({ form });
  };

  onValidate = (e, name) => {
    e.preventDefault();
    let { form } = this.state;
    // console.log({ form });
    // if (name === "employeeName") {
    //   if (form[name].trim() === "" || !/^[a-zA-Z ]*$/.test(form[name])) {
    //     form.errors[name] = true;
    //   } else form.errors[name] = false;
    // }
    if (name === "employeeFirstName") {
      if (form[name].trim() === "" || !/^[a-zA-Z]*$/.test(form[name])) {
        form.errors[name] = true;
      } else form.errors[name] = false;
    } else if (name === "employeeLastName") {
      if (form[name].trim() === "" || !/^[a-zA-Z]*$/.test(form[name])) {
        form.errors[name] = true;
      } else form.errors[name] = false;
    } else if (name === "employeeId") {
      if (form[name].trim() === "" || !/^[A-Z0-9]*$/.test(form[name])) {
        form.errors[name] = true;
      } else form.errors[name] = false;
    } else if (name === "emailId") {
      if (
        !form[name].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ||
        form[name].trim() === ""
      ) {
        form.errors[name] = true;
      } else form.errors[name] = false;
    } else if (name === "mobileNo") {
      if (form[name].trim() === "" || !/^[6789]\d{9}$/.test(form[name])) {
        form.errors[name] = true;
      } else form.errors[name] = false;
    } else if (name === "role") {
      if (form[name] === "") {
        form.errors[name] = true;
      } else form.errors[name] = false;
    } else if (name === "spokeDTOSet") {
      if (form[name] === null) {
        form.errors["spokeDTO"] = true;
      } else form.errors["spokeDTO"] = false;
    } else {
      if (form[name].id === null) {
        form.errors[name] = true;
      } else form.errors[name] = false;
    }

    this.setState({ form });
  };

  handleSelectChanges = (e, name) => {
    console.log("handleSegmentSelectChanges", e, name);
    let { form } = this.state;
    let { statusValue } = e;
    form[name] = statusValue;
    this.setState({ form });
    this.setState({ showUpdate: false });
  };

  clearAllBranch = () => {
    let { form } = this.state;
    form.branchDTO = { branchName: "", id: "" };
    this.setState({ form });
  };
  clearAllRegion = () => {
    let { form } = this.state;
    form.region = "";
    this.setState({ form });
  };

  clearState = () => {
    let { form, stateDropdownValue } = this.state;
    console.log("rform", form);
    form.stateDTO = { id: "", stateName: "" };
    // form.stateDTO.id = "";
    // form.stateDTO.stateName = "";
    this.setState({
      form,
      stateDropdownValue: [],
    });
  };

  clearRegion = () => {
    let { form, regionDropdownValue } = this.state;
    form.regionDTO = {
      id: "",
      regionName: "",
    };
    // form.regionDTO.id = "";
    // form.regionDTO.regionName = "";
    this.setState({
      form,
      regionDropdownValue: [],
    });
  };

  clearBranch = () => {
    let { form, branchDropdownValue } = this.state;
    form.branchDTO = {
      id: "",
      branchName: "",
    };

    // form.branchDTO.id = "";
    // form.branchDTO.branchName = "";
    this.setState({
      form,
      branchDropdownValue: [],
    });
  };

  clearSpoke = () => {
    let { form, villageDropdownValue } = this.state;
    form.spokeDTOSet = null;
    this.setState({
      form,
      villageDropdownValue: [],
    });
  };
  clearZone = () => {
    let { form, zoneDropdownValue } = this.state;
    form.zoneDTO.id = "";
    form.zoneDTO.zoneName = "";
    this.setState({
      form,
      zoneDropdownValue: [],
    });
  };

  render() {
    let {
      form,
      showModal,
      showInner,
      isLoading,
      userBranch,
      userZone,
      userState,
      userRegion,
      userVillage,
      key,
      zoneDropdownValue,
      stateDropdownValue,
      regionDropdownValue,
      branchDropdownValue,
      branchAdditinalDropdownValue,
      villageDropdownValue,
      loaderForSpoke,
      showZone,
      showState,
      showRegion,
      showBranch,
      showSpoke,
      showUpdate,
      showGeography,
    } = this.state;
    let { roleDropdownValue, role, userInfo } = this.props;
    let { errors } = form;
    let diableSave = false;
    //  let showUpdate = false;
    let isRequiredFeildFilled = false;
    console.log("spokelist", villageDropdownValue);
    console.log("okkk", branchDropdownValue);
    console.log("form", form);
    console.log("showUpdate", showUpdate);
    console.log("frm", branchDropdownValue);
    Object.keys(form).map((name) => {
      // console.log("form", form[name]);
      if (form[name] == "" || form[name] == null || form[name] == undefined) {
        if (
          (name === "zoneDTO" && showZone) ||
          (name === "stateDTO" && showState) ||
          (name === "regionDTO" && showRegion) ||
          (name === "branchDTO" && showBranch) ||
          (name === "spokeDTOSet" && showSpoke)
        ) {
          if (form && form[name] && form[name].id === "") {
            console.log("first", name, form[name]);
            isRequiredFeildFilled = true;
          }
          console.log("isRequiredFeildFilled1", isRequiredFeildFilled);
        } else if (
          form.action === "add" &&
          name !== "zoneDTO" &&
          name !== "stateDTO" &&
          name !== "regionDTO" &&
          name !== "branchDTO" &&
          name !== "spokeDTOSet" &&
          name !== "zone" &&
          name !== "region" &&
          name !== "branch" &&
          name != "spoke"
        ) {
          isRequiredFeildFilled = true;
          console.log("secound", name);
          console.log("isRequiredFeildFilled2", isRequiredFeildFilled, name);
        } else if (
          form.action === "edit" &&
          name !== "zoneDTO" &&
          name !== "stateDTO" &&
          name !== "regionDTO" &&
          name !== "branchDTO" &&
          name !== "spokeDTOSet" &&
          name !== "zone" &&
          name !== "region" &&
          name !== "branch" &&
          name != "spoke"
        ) {
          isRequiredFeildFilled = false;
          console.log("secound", name);
          console.log("isRequiredFeildFilled2", isRequiredFeildFilled, name);
        }
      }
    });
    console.log("rform", form);
    console.log(
      "chesktodisable",
      showZone,
      showState,
      showRegion,
      showBranch,
      showSpoke
    );
    // let userData = JSON.parse(localStorage.getItem("userData"));
    // console.log("userdata", userData);
    // let userBranchmapping = [];
    // userBranch &&
    //   userBranch.map((val) => {
    //     userBranchmapping.push({
    //       employeeId: userData && userData.adminEmployeeId,
    //       branchName: val.branchName,
    //       id: val.id,
    //     });
    //   });

    // let branchobj = [];
    // branchDropdownValue.map((res) => {
    //   userBranch &&
    //     userBranch.map((val) => {
    //       if (res.branchName == val.branchName) {
    //         branchobj.push(res);
    //       }
    //     });
    // });
    // console.log("branchobj", branchobj);

    // let zoneobj = [];
    // zoneDropdownValue.map((res) => {
    //   userZone &&
    //     userZone.map((val) => {
    //       if (res.zoneName == val.zoneName) {
    //         zoneobj.push(res);
    //       }
    //     });
    // });

    // let regionobj = [];
    // regionDropdownValue.map((res) => {
    //   userRegion &&
    //     userRegion.map((val) => {
    //       if (res.regionName == val.regionName) {
    //         regionobj.push(res);
    //       }
    //     });
    // });

    let villageobj = [];
    villageDropdownValue &&
      villageDropdownValue.map((res) => {
        userVillage &&
          userVillage.map((val) => {
            if (res.id == val.id) {
              villageobj.push(res);
            }
          });
      });

    Object.keys(errors).map((error) => {
      if (errors[error] == true) {
        console.log("disablesave", diableSave);
        diableSave = true;
        return true;
      }
    });

    if (zoneDropdownValue.length > 0) {
      if (
        role === "ZBH" ||
        role === "ZCM" ||
        role === "RBH" ||
        role === "RCM" ||
        role === "BM"
      ) {
        zoneDropdownValue =
          (zoneDropdownValue &&
            zoneDropdownValue.filter((e) =>
              e.zonename.includes(userInfo.zone)
            )) ||
          [];
      }
    }
    // if (stateDropdownValue.length > 0) {
    // 	if (role === "ZBH" || role === "ZCM" || role === "RBH" || role === "RCM" || role === "BM"){
    // 		stateDropdownValue = (stateDropdownValue && stateDropdownValue.filter((e) => e.statename.includes(userInfo.state))) || [];
    // 	}
    // }

    if (regionDropdownValue.length > 0) {
      if (role === "RBH" || role === "RCM" || role === "BM") {
        regionDropdownValue =
          (regionDropdownValue &&
            regionDropdownValue.filter((e) =>
              e.regionName.includes(userInfo.region)
            )) ||
          [];
      }
    }

    if (branchDropdownValue.length > 0) {
      if (role === "BM") {
        branchDropdownValue =
          (branchDropdownValue &&
            branchDropdownValue.filter((e) =>
              e.branchName.includes(userInfo.branch)
            )) ||
          [];
      }
    }

    if (branchAdditinalDropdownValue.length > 0) {
      if (role === "BM") {
        branchAdditinalDropdownValue =
          (branchAdditinalDropdownValue &&
            branchAdditinalDropdownValue.filter((e) =>
              e.branchName.includes(userInfo.branch)
            )) ||
          [];
      }
    }
    console.log("isloading", diableSave, isRequiredFeildFilled, isLoading);
    console.log(
      "state",
      showState && form.errors.stateDTO == true,
      showSpoke,
      form.errors.spokeDTO
    );
    console.log(
      "zone",
      showZone && form.errors.zoneDTO == true,
      showSpoke,
      form.errors.spokeDTO
    );
    console.log(
      "region",
      showRegion && form.errors.regionDTO == true,
      showSpoke,
      form.errors.spokeDTO
    );
    console.log(
      "branch",
      showBranch && form.errors.branchDTO == true,
      showSpoke,
      form.errors.spokeDTO
    );

    console.log("zoneDropdownValue", zoneDropdownValue[0]);

    console.log("showUpdate", showUpdate);
    console.log("diableSave", diableSave);
    console.log("isLoading", isLoading);
    console.log("isRequiredFeildFilled", isRequiredFeildFilled);
    console.log("showSpoke", showSpoke);
    console.log("showBranch", showBranch);
    console.log("showRegion", showRegion);
    console.log("showState", showState);
    console.log("showZone", showZone);
    form && form.zoneDTO && console.log("zoneDTO", form.zoneDTO);
    console.log("action", this.props.action);

    console.log("spokeDTOSet", form.spokeDTOSet);
    console.log("branchid", form.branchDTO);
    console.log("regionid", form.regionDTO);
    console.log("stateid", form.stateDTO);
    console.log("zoneid", form.zoneDTO);
    console.log("showGeography", showGeography);

    return (
      <>
        <Modal
          className="add-role"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.action == "edit" ? "Edit User" : "Add User"}
            </Modal.Title>
          </Modal.Header>
          {/* {roleDropdownValue &&
            roleDropdownValue.length > 0 &&
            zoneDropdownValue &&
            zoneDropdownValue.length > 0 &&
            regionDropdownValue &&
            regionDropdownValue.length > 0 &&
            branchDropdownValue &&
            branchDropdownValue.length > 0 && ( */}
          <Modal.Body className="p-4">
            {isLoading ? (
              <h4 className="text-center">LOADING...</h4>
            ) : (
              <>
                <div className="row ">
                  {/* <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Employee Name
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="employeeName"
                          value={form.employeeName}
                          onChange={(e) => {
                            this.handleInputChange(e, "employeeName");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "employeeName");
                          }}
                          placeholder="Enter employeeName"
                          className={`form-input ${
                            errors.employeeName ? "error-input-border" : ""
                          } `}
                        />
                        {errors.employeeName && (
                          <span className="errorMsg">
                            {errors.employeeName == true
                              ? "Enter a valid Employee Name"
                              : errors.employeeName}
                          </span>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Vertical
                    </label>
                    <Select
                      value={verticalDropdown?.find(
                        ({ verticalValue }) =>
                          verticalValue === this?.state?.form?.vertical
                      )}
                      onChange={(e) => {
                        this.handleVerticalChange(e, "vertical");
                      }}
                      name="vertical"
                      options={verticalDropdown}
                      placeholder="Select Vertical"
                      valueKey="verticalValue"
                      labelKey="verticalLabel"
                      getOptionLabel={(option) => option.verticalLabel}
                      getOptionValue={(option) => option.verticalValue}
                    />
                    {errors.vertical && (
                      <span className="errorMsg">Please Select Vertical</span>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> First Name
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="employeeFirstName"
                          value={form.employeeFirstName}
                          onChange={(e) => {
                            this.handleInputChange(e, "employeeFirstName");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "employeeFirstName");
                          }}
                          placeholder="Enter employeeFirstName"
                          className={`form-input ${
                            errors.employeeFirstName ? "error-input-border" : ""
                          } `}
                        />
                        {errors.employeeFirstName && (
                          <span className="errorMsg">
                            {errors.employeeFirstName == true
                              ? "Enter a valid Employee First Name"
                              : errors.employeeFirstName}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Last Name
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="employeeLastName"
                          value={form.employeeLastName}
                          onChange={(e) => {
                            this.handleInputChange(e, "employeeLastName");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "employeeLastName");
                          }}
                          placeholder="Enter employeeLastName"
                          className={`form-input ${
                            errors.employeeLastName ? "error-input-border" : ""
                          } `}
                        />
                        {errors.employeeLastName && (
                          <span className="errorMsg">
                            {errors.employeeLastName == true
                              ? "Enter a valid Employee Name"
                              : errors.employeeLastName}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* emp ID */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Employee ID
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          maxLength="6"
                          name="employeeId"
                          value={form && form.employeeId}
                          onChange={(e) => {
                            this.handleInputChange(e, "employeeId");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "employeeId");
                          }}
                          placeholder="Enter employeeId"
                          className={`form-input ${
                            errors.employeeId ? "error-input-border" : ""
                          } `}
                          disabled={this.props.action == "edit"}
                        />
                        {errors.employeeId && (
                          <span className="errorMsg">
                            {errors.employeeId == true
                              ? "Enter a valid Employee ID"
                              : errors.employeeId}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* email ID */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Email ID
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          //   maxLength="9"
                          name="emailId"
                          value={form.emailId}
                          onChange={(e) => {
                            this.handleInputChange(e, "emailId");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "emailId");
                          }}
                          placeholder="Enter Email ID"
                          className={`form-input ${
                            errors.emailId ? "error-input-border" : ""
                          } `}
                          // disabled={this.props.action == "edit"}
                        />
                        {errors.emailId && (
                          <span className="errorMsg">
                            {errors.emailId == true
                              ? "Enter a valid Email ID"
                              : errors.emailId}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* mobile number */}
                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label>
                        <i className="text-danger">*</i> Mobile Number
                      </label>
                      <div class="inner-addon right-addon">
                        <input
                          type="text"
                          // pattern="\d*"
                          maxLength="10"
                          name="mobileNo"
                          value={form && form.mobileNo}
                          onChange={(e) => {
                            this.handleInputChange(e, "mobileNo");
                          }}
                          onBlur={(e) => {
                            this.onValidate(e, "mobileNo");
                          }}
                          placeholder="Enter Mobile Number"
                          className={`form-input ${
                            errors.mobileNo ? "error-input-border" : ""
                          } `}
                          reqType="number"
                        />
                        {errors.mobileNo && (
                          <span className="errorMsg">
                            {errors.mobileNo == true
                              ? "Enter a valid Mobile Number"
                              : errors.mobileNo}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Role */}
                  <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Role
                    </label>
                    <Select
                      defaultValue={
                        roleDropdownValue.filter(
                          ({ roleName }) => roleName == form.role
                        )[0]
                      }
                      onChange={(e) => {
                        this.handleroleSelectChange(e, "role");
                      }}
                      onBlur={(e) => this.onValidate(e, "role")}
                      name="role"
                      options={roleDropdownValue}
                      placeholder="Select role"
                      valueKey="roleName"
                      labelKey="roleName"
                      getOptionLabel={(option) => option.roleName}
                      getOptionValue={(option) => option.roleName}
                      className={`${errors.role ? "error-input-border" : ""} `}
                    />
                    {errors.role && (
                      <span className="errorMsg">Please Select Role</span>
                    )}
                  </div>

                  {/* status */}
                  {this.props.action == "edit" && (
                    <div className="col-md-12 mt-3">
                      <label>
                        <i className="text-danger">*</i> Status
                      </label>
                      <Select
                        defaultValue={
                          statusType.filter(
                            ({ statusValue }) => statusValue == form.status
                          )[0]
                        }
                        onChange={(e) => {
                          this.handleSelectChanges(e, "status");
                        }}
                        onBlur={(e) => this.onValidate(e, "status")}
                        name="status"
                        options={statusType}
                        placeholder="Select Status"
                        valueKey="statusLabel"
                        labelKey="statusValue"
                        getOptionLabel={(option) => option.statusValue}
                        getOptionValue={(option) => option.statusLabel}
                        className={`${
                          errors.status ? "error-input-border" : ""
                        } `}
                      />
                      {errors.status && (
                        <span className="errorMsg">Please Select Status</span>
                      )}
                    </div>
                  )}
                  {/* Zone */}
                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Zone
                    </label>
                    <Select
                      defaultValue={zoneDropdownValue.filter(
                        ({ zoneName }) => zoneName == form.zone
                      )}
                      //   value={form.zone}
                      onChange={(e, value) => {
                        this.handlezoneSelectChange(e, "zone", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "zone")}
                      name="zone"
                      options={zoneDropdownValue}
                      placeholder="Select zone"
                      valueKey="zoneName"
                      labelKey="zonename"
                      getOptionLabel={(option) => option.zonename}
                      getOptionValue={(option) => option.zoneName}
                      className={`${errors.zone ? "error-input-border" : ""} `}
                      isDisabled={false}
                      isMulti={true}
                    />
                    {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )}
                  </div> */}
                  {showZone &&
                    zoneDropdownValue &&
                    zoneDropdownValue.length > 0 && (
                      <div className="col-md-12 mt-3">
                        <label>
                          <i className="text-danger">*</i> Zone
                        </label>
                        {/* <Select
                      value={zoneobj}
                      onChange={this.handleZone}
                      // onBlur={(e) => this.onValidate(e, "zone")}
                      name="zoneobj"
                      options={zoneDropdownValue}
                      placeholder="Select zone"
                      valueKey="zoneName"
                      labelKey="zonename"
                      getOptionLabel={(option) => option.zonename}
                      getOptionValue={(option) => option.zoneName}
                      className={`${errors.zone ? "error-input-border" : ""} `}
                      isDisabled={false}
                      isMulti={true}
                    /> */}
                        {/* {errors.zone && (
                      <span className="errorMsg">Please Select Zone</span>
                    )} */}
                        <Select
                          defaultValue={
                            zoneDropdownValue &&
                            zoneDropdownValue.filter(
                              ({ zoneName }) =>
                                zoneName == form.zoneDTO.zoneName
                            )[0]
                          }
                          onChange={(e) => {
                            this.handleZoneSelectChange(e, "zoneDTO");
                          }}
                          //onBlur={(e) => this.onValidate(e, "zoneDTO")}
                          name="zoneDTO"
                          options={zoneDropdownValue}
                          placeholder="Select Zone"
                          valueKey="zoneName"
                          labelKey="zoneName"
                          getOptionLabel={(option) => option.zoneName}
                          getOptionValue={(option) => option.zoneName}
                          className={`${
                            errors && errors.zoneDTO ? "error-input-border" : ""
                          } `}
                        />
                        {errors && errors.zoneDTO && (
                          <span className="errorMsg">Please Select Zone</span>
                        )}
                      </div>
                    )}

                  {/* new Zone */}
                  {/* Region */}

                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Region
                    </label>
                    <Select
                      defaultValue={regionDropdownValue.filter(
                        ({ regionName }) => regionName == form.region
                      )}
                      onChange={(e, value) => {
                        this.handleregionSelectChange(e, "region", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "region")}
                      name="region"
                      options={regionDropdownValue}
                      placeholder="Select region"
                      valueKey="regionName"
                      labelKey="regionName"
                      getOptionLabel={(option) => option.regionName}
                      getOptionValue={(option) => option.regionName}
                      className={`${
                        errors.region ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti={true}
                    />
                    {errors.region && (
                      <span className="errorMsg">Please Select Region</span>
                    )}
                  </div> */}

                  {/*new state*/}
                  {showState &&
                    stateDropdownValue &&
                    stateDropdownValue.length > 0 && (
                      <div className="col-md-12 mt-3">
                        <label>
                          <i className="text-danger">*</i> State
                        </label>
                        <Select
                          defaultValue={
                            (console.log(
                              "stateDropdownValue",
                              stateDropdownValue &&
                                stateDropdownValue.filter(
                                  ({ stateName }) =>
                                    stateName == form.stateDTO.stateName
                                )[0]
                            ),
                            stateDropdownValue &&
                              stateDropdownValue.filter(
                                ({ stateName }) =>
                                  stateName == form.stateDTO.stateName
                              )[0])
                          }
                          onChange={(e) => {
                            this.handleStateSelectChange(e, "stateDTO");
                          }}
                          //  onBlur={(e) => this.onValidate(e, "stateDTO")}
                          name="state"
                          options={stateDropdownValue}
                          placeholder="Select State"
                          valueKey="stateName"
                          labelKey="stateName"
                          getOptionLabel={(option) => option.stateName}
                          getOptionValue={(option) => option.stateName}
                          className={`${
                            errors && errors.stateDTO
                              ? "error-input-border"
                              : ""
                          } `}
                          key={key}
                        />
                        {errors && errors.stateDTO && (
                          <span className="errorMsg">Please Select State</span>
                        )}
                      </div>
                    )}

                  {/* new Region */}
                  {showRegion &&
                    regionDropdownValue &&
                    regionDropdownValue.length > 0 && (
                      <div className="col-md-12 mt-3">
                        <label>
                          <i className="text-danger">*</i> Region
                        </label>
                        {/* <Select
                      value={regionobj}
                      onChange={this.handleRegion}
                      // onBlur={(e) => this.onValidate(e, "region")}
                      name="regionobj"
                      options={regionDropdownValue}
                      placeholder="Select region"
                      valueKey="regionName"
                      labelKey="regionName"
                      getOptionLabel={(option) => option.regionName}
                      getOptionValue={(option) => option.regionName}
                      className={`${
                        errors.region ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti={true}
                    /> */}
                        {/* {errors.region && (
                      <span className="errorMsg">Please Select Region</span>
                    )} */}
                        <Select
                          defaultValue={
                            (console.log(
                              "regionDropdownValue",
                              (regionDropdownValue &&
                                regionDropdownValue.filter(
                                  ({ regionName }) =>
                                    regionName == form.regionDTO.region
                                )) ||
                                ""
                            ),
                            (regionDropdownValue &&
                              regionDropdownValue.filter(
                                ({ regionName }) =>
                                  regionName == form.regionDTO.regionName
                              )) ||
                              "")
                          }
                          onChange={(e) => {
                            this.handleRegionSelectChange(e, "regionDTO");
                          }}
                          //   onBlur={(e) => this.onValidate(e, "regionDTO")}
                          name="regionDTO"
                          options={regionDropdownValue}
                          placeholder="Select Region"
                          valueKey="regionName"
                          labelKey="regionName"
                          getOptionLabel={(option) => option.regionName}
                          getOptionValue={(option) => option.regionName}
                          className={`${
                            errors && errors.regionDTO
                              ? "error-input-border"
                              : ""
                          } `}
                          key={key}
                        />
                        {errors && errors.regionDTO && (
                          <span className="errorMsg">Please Select Region</span>
                        )}
                      </div>
                    )}

                  {/* Branch */}
                  {/* <div className="col-md-12 mt-3">
                    <label>
                      <i className="text-danger">*</i> Branch
                    </label>
                    <Select
                      defaultValue={branchDropdownValue.map(
                        ({ branchName }) => branchName == form.branch
                      )}
                      onChange={(e, value) => {
                        this.handlebranchSelectChange(e, "branch", value);
                      }}
                      onBlur={(e) => this.onValidate(e, "branch")}
                      name="branch"
                      options={branchDropdownValue}
                      placeholder="Select branch"
                      valueKey="branchName"
                      labelKey="branchName"
                      getOptionLabel={(option) => option.branchName}
                      getOptionValue={(option) => option.branchName}
                      className={`${
                        errors.branch ? "error-input-border" : ""
                      } `}
                      isDisabled={false}
                      isMulti
                    />
                    {errors.branch && (
                      <span className="errorMsg">Please Select Branch</span>
                    )}
                  </div> */}

                  {/* new branch */}
                  {showBranch &&
                    branchDropdownValue &&
                    branchDropdownValue.length > 0 && (
                      <div className="col-md-12 mt-3">
                        <label>
                          <i className="text-danger">*</i> Branch
                        </label>
                        {/* <Select
                      // defaultValue={branchDropdownValue.map(
                      //   ({ branchName }) => branchName == form.branch
                      // )}
                      value={branchobj}
                      onChange={this.handlebranch}
                      // onBlur={(e) => this.onValidate(e, "branch")}
                      name="branchobj"
                      options={branchDropdownValue}
                      placeholder="Select branch"
                      valueKey="branchName"
                      labelKey="branchName"
                      getOptionLabel={(option) => option["branchName"]}
                      getOptionValue={(option) => option["branchName"]}
                      styles={dropstyle}
                      // className={`${
                      //   errors.branch ? "error-input-border" : ""
                      // } `}
                      isDisabled={false}
                      isMulti
                    /> */}
                        {/* {errors.branch && (
                      <span className="errorMsg">Please Select Branch</span>
                    )} */}
                        <Select
                          defaultValue={
                            branchDropdownValue.filter(
                              ({ branchName }) =>
                                branchName == form.branchDTO.branchName
                            )[0] || ""
                          }
                          onChange={(e) => {
                            this.handleBranchSelectChange(e, "branchDTO");
                          }}
                          // onBlur={(e) => this.onValidate(e, "branchDTO")}
                          name="branchDTO"
                          options={branchDropdownValue}
                          placeholder="Select Branch"
                          valueKey="branchName"
                          labelKey="branchName"
                          getOptionLabel={(option) => option.branchName}
                          getOptionValue={(option) => option.branchName}
                          className={`${
                            errors && errors.branchDTO
                              ? "error-input-border"
                              : ""
                          } `}
                          key={key}
                        />
                        {errors && errors.branchDTO && (
                          <span className="errorMsg">Please Select Branch</span>
                        )}
                      </div>
                    )}

                  {/* {showBranch &&
                    branchAdditinalDropdownValue &&
                    branchAdditinalDropdownValue.length > 0 && (
                      <div className="col-md-12 mt-3">
                        {
                          <>
                            <label>State branches</label>
                            <Select
                              value={form.nonPrimaryBranchSet}
                              onChange={this.handleAdditionalBranches}
                              name="nonPrimaryBranchSet"
                              options={branchAdditinalDropdownValue}
                              placeholder="Select additional branches"
                              valueKey="branchName"
                              labelKey="branchName"
                              getOptionLabel={(option) => option.branchName}
                              getOptionValue={(option) => option.branchName}
                              isDisabled={false}
                              isMulti={true}
                              key={key}
                            />
                          </>
                        }
                        {errors.spokeDTO && (
                          <span className="errorMsg">
                            Please Select Additional branches
                          </span>
                        )}
                      </div>
                    )} */}

                  {showBranch &&
                    branchAdditinalDropdownValue &&
                    branchAdditinalDropdownValue.length > 0 && (
                      <div className="col-md-12 mt-3">
                        <>
                          <label>{this.state.form.role === "RFM" ? "Region Branches" : "State branches"}</label>
                          <Select
                            value={form.nonPrimaryBranchSet}
                            onChange={this.handleAdditionalBranches}
                            name="nonPrimaryBranchSet"
                            options={this.state.form.role === "RFM" ? branchDropdownValue : branchAdditinalDropdownValue}
                            placeholder={`Select ${this.state.form.role === "RFM" ? "Region" : "additional"} branches`}
                            valueKey="branchName"
                            labelKey="branchName"
                            getOptionLabel={(option) => option.branchName}
                            getOptionValue={(option) => option.branchName}
                            isDisabled={false}
                            isMulti={true}
                            key={key}
                          />
                        </>
                        {errors.spokeDTO && (
                          <span className="errorMsg">
                            Please Select {this.state.form.role === "RFM" ? "Region" : "Additional"} branches
                          </span>
                        )}
                      </div>
                    )}

                  {showSpoke &&
                    villageDropdownValue &&
                    villageDropdownValue.length > 0 && (
                      <div className="col-md-12 mt-3">
                        {loaderForSpoke ? (
                          <React.Fragment>
                            <i class="fa fa-spinner fa-spin ml-3"></i>
                            <span className="ml-2">Please wait...</span>
                          </React.Fragment>
                        ) : (
                          <>
                            <label>
                              <i className="text-danger">*</i> Spoke
                            </label>
                            <Select
                              //   value={console.log("hellooo",form.spokeDTOSet && form.spokeDTOSet.map((item)=>item ))}
                              //    value={console.log("hellooo",
                              //      villageDropdownValue.filter(
                              //        ({ spokeDTOSet }) => spokeDTOSet == form.spokeDTOSet
                              //      ) || ''
                              //  )}

                              //  defaultValue={
                              //    (console.log(
                              //   "regionDropdownValue22",
                              // villageDropdownValue &&
                              //   villageDropdownValue.filter(
                              //     ({ spokeName }) => spokeName ==form.spokeDTOSet&& form.spokeDTOSet.spokeName
                              //   ) || ''
                              // ),
                              //   villageDropdownValue &&
                              //   villageDropdownValue.filter(
                              //     ({ spokeName }) => spokeName == form.spokeDTOSet&&form.spokeDTOSet.spokeName
                              //   )[0]||''
                              // }

                              // defaultValue={
                              //   villageDropdownValue.filter(
                              //     ({ spokeName }) => spokeName == form.spokeDTOSet.spokeName)
                              // }

                              //  defaultValue={
                              //    villageDropdownValue&&
                              //   villageDropdownValue.filter(
                              //     ({ spokeName }) => spokeName == form.spokeDTOSet&&form.spokeDTOSet.spokeName
                              //     )[0]
                              // }

                              value={form.spokeDTOSet}
                              onChange={this.handleVillage}
                              //  onBlur={(e) => this.onValidate(e, "spokeDTOSet")}
                              name="spokeDTOSet"
                              options={villageDropdownValue}
                              placeholder="Select Village / Spoke"
                              valueKey="spokeName"
                              labelKey="spokeName"
                              getOptionLabel={(option) => option.spokeName}
                              getOptionValue={(option) => option.spokeName}
                              // getOptionLabel={(option) => option.info}
                              // getOptionValue={(option) => option.id}
                              className={`${
                                errors.spokeDTO ? "error-input-border" : ""
                              } `}
                              isDisabled={false}
                              isMulti={true}
                              key={key}
                            />
                          </>
                        )}
                        {errors.spokeDTO && (
                          <span className="errorMsg">
                            Please Select Village / Spoke
                          </span>
                        )}
                      </div>
                    )}
                </div>
              </>
            )}
            <Modal.Footer>
              <Button className="btn-danger" onClick={this.handleModalClose}>
                Cancel
              </Button>
              <Button
                className="btn-success"
                //       disabled={
                //         diableSave ||
                //         isRequiredFeildFilled
                //         ||
                //  isLoading ||
                //         // userBranch == undefined ||
                //         // userZone == undefined ||
                //         // userRegion == undefined ||
                //         // (userBranch && userBranch.length == 0) ||
                //         // (userZone && userZone.length == 0) ||
                //         // (userVillage && userVillage.length == 0)
                //   (showSpoke && villageobj && villageobj.length == 0)
                //         // (showBranch && branchobj && branchobj.length == 0)
                //         // (showRegion && regionobj && regionobj.length == 0)
                //         // (showState && stateobj && stateobj.length == 0)
                //         // (showZone && zoneobj && zoneobj.length == 0)

                //      }

                disabled={
                  diableSave ||
                  isRequiredFeildFilled ||
                  isLoading ||
                  (this.props.action === "add" &&
                    showSpoke &&
                    form["spokeDTOSet"] === null) ||
                  (this.props.action === "add" &&
                    showBranch &&
                    form["branchDTO"].id === "") ||
                  (this.props.action === "add" &&
                    showRegion &&
                    form["regionDTO"].id === "") ||
                  (this.props.action === "add" &&
                    showState &&
                    form["stateDTO"].id === "") ||
                  (this.props.action === "add" &&
                    showZone &&
                    form["zoneDTO"].id === "") ||
                  (this.props.action === "edit" &&
                    showUpdate &&
                    showSpoke &&
                    form.spokeDTOSet === null) ||
                  (this.props.action === "edit" &&
                    showUpdate &&
                    showBranch &&
                    form.branchDTO.id === "") ||
                  (this.props.action === "edit" &&
                    showUpdate &&
                    showRegion &&
                    form.regionDTO.id === "") ||
                  (this.props.action === "edit" &&
                    showUpdate &&
                    showState &&
                    form.stateDTO.id === "") ||
                  (this.props.action === "edit" &&
                    showUpdate &&
                    showZone &&
                    form.zoneDTO.id === "") ||
                  showGeography
                }
                //                disabled={isLoading||(showSpoke && villageobj )||(showZone&&form&&form.zoneDTO&&form.zoneDTO.length<0)
                // ||(showState&&form.stateDTO.length<0)||(showRegion&&form&&form.regionDTO&&form.regionDTO.length<0)||(showBranch&&form&&form.branchDTO&&form.branchDTO.length<0)
                //                }
                onClick={this.handleSubmit}
              >
                {this.props.action == "edit" ? "Update" : "Create"}
              </Button>
            </Modal.Footer>
          </Modal.Body>
          {/* )} */}
        </Modal>
        <CloseModal
          show={showInner}
          button2={this.handleModalClose}
          button1={this.innerModalClose}
          title="Do you want to close ?"
        />
      </>
    );
  }
}

export default Addedituser;
